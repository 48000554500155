import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { catchError, tap } from 'rxjs/operators';

import {
  IAthlete,
  IClub,
  ICompetition,
  IDisciplineMetadata,
  IRegistrationDetail,
  IResult,
} from '@beathletics/api-interfaces';
import { throwError } from 'rxjs';
import { NewCompetitionApiService } from './new-competition-api.service';
import {
  AddAthleteOrClubAsVip,
  AddOfficialForCompetition,
  CreateNewCompetition,
  CreateResult,
  DeleteContactOfCompetition,
  DeleteDisciplinesAndResults,
  DeleteEventResult,
  DeleteFullCompetition,
  DeleteRegistrationPricing,
  DeleteRegistrationRule,
  EditDisciplineEventType,
  EditResultData,
  GenerateSyncCode,
  LoadDisciplineLiveResults,
  ProcessResultFileUpload,
  PushUpdatedData,
  ReSyncShop,
  ReloadCompetitionLogs,
  RemoveVip,
  ReplaceCompetitionMetadata,
  ResetShop,
  SaveCompetitionData,
  SaveCustomRegistrationRule,
  SaveLocalTeamsList,
  SavePaymentOption,
  SavePricing,
  SaveRegistrationDetails,
  SelectOrLoadCompetition,
  UpdateResultsStatus,
} from './new-competition.action';
import { NewCompetitionStateModel } from './new-competition.model';

@State<NewCompetitionStateModel>({
  name: 'newCompetitions',
  defaults: {
    competitions: {},
    logs: {},
    results: {},
    lastSeasonBests: {},
    selectedCompetition: '',
    selectedDiscipline: 0,
  },
})
@Injectable()
export class NewCompetitionState {
  private apiService = inject(NewCompetitionApiService);
  private store = inject(Store);

  @Selector()
  static selectedCompetitionData(state: NewCompetitionStateModel) {
    return state?.competitions?.[state.selectedCompetition];
  }

  @Selector()
  static loadedResults(state: NewCompetitionStateModel) {
    return state.results;
  }

  @Selector()
  static selectedCompetition(state: NewCompetitionStateModel) {
    return state?.selectedCompetition;
  }

  @Selector()
  static selectedCompetitionContacts(state: NewCompetitionStateModel) {
    if (state.competitions?.[state.selectedCompetition]?.contacts) {
      const contacts = [...state.competitions[state.selectedCompetition].contacts];
      return contacts.sort((a, b) => b.function.localeCompare(a.function));
    } else {
      return [];
    }
  }

  @Selector()
  static selectedCompetitionLogs(state: NewCompetitionStateModel) {
    return state.logs[state.selectedCompetition];
  }

  @Selector()
  static selectedCompetitionDataAndResultsOfSelectedCompetition(state: NewCompetitionStateModel) {
    return {
      competition: state.competitions[state.selectedCompetition],
      results: state.results[state.selectedCompetition]
        ? Object.keys(state.results[state.selectedCompetition]).map(
            (key) => state.results[state.selectedCompetition][key],
          )
        : [],
    };
  }

  @Selector()
  static resultsOfSelectedCompetitionByIds(state: NewCompetitionStateModel) {
    return state.results[state.selectedCompetition] || {};
  }

  @Selector()
  static getResultById(state: NewCompetitionStateModel) {
    return (id: string) => state.results[state.selectedCompetition]?.[id];
  }

  @Selector()
  static resultsOfSelectedCompetition(state: NewCompetitionStateModel) {
    if (state.results[state.selectedCompetition]) {
      return Object.keys(state.results[state.selectedCompetition]).map(
        (key) => state.results[state.selectedCompetition][key],
      );
    }
    return [];
  }

  @Selector()
  static lastAthleteSeasonBestsOfSelectedCompetition(state: NewCompetitionStateModel) {
    if (state.lastSeasonBests[state.selectedCompetition]) {
      return state.lastSeasonBests[state.selectedCompetition];
    }
    return [];
  }

  @Selector()
  static selectedCompetitionVips(state: NewCompetitionStateModel) {
    const allVips = state.competitions[state.selectedCompetition]?.registrationDetail?.registrationsVip || [];
    const athletes: IAthlete[] = [];
    const clubs: IClub[] = [];
    for (const vip of allVips) {
      if (vip.athlete) {
        athletes.push(vip.athlete);
      } else if (vip.club) {
        clubs.push(vip.club);
      }
    }
    return {
      athletes: athletes.sort((a, b) => (a?.person?.lastName || 'z').localeCompare(b?.person?.lastName || 'z')),
      clubs: clubs.sort((a, b) => (a?.abbr || 'z').localeCompare(b?.abbr || 'z')),
    };
  }

  @Selector()
  static selectedCompetitionRegistrationDetails(state: NewCompetitionStateModel) {
    const details = state.competitions[state.selectedCompetition]?.registrationDetail;
    if (details?.startDate && details?.endDate) {
      return {
        managed: details.managed,
        validated: details.validated,
        start: new Date(details.startDate).getTime(),
        end: new Date(details.endDate).getTime(),
        onSiteAllowed: details.onSiteAllowed,
        publicMsg: details.publicMsg,
        externalPdf: details.externalPdf,
        showNbParticipants: details.showNbParticipants,
        showParticipantsList: details.showParticipantsList,
        selection: details.selection,
        previewMsg: details.previewMsg,
        closureMsg: details.closureMsg,
        maxSingle: details.maxSingle,
        maxRelay: details.maxRelay,
        paymentOption: details.paymentOption,
      };
    } else {
      return {
        managed: false,
      };
    }
  }

  @Selector()
  static selectedCompetitionRegistrationRules(state: NewCompetitionStateModel) {
    return state.competitions[state.selectedCompetition]?.registrationDetail?.registrationsRules;
  }

  @Selector()
  static selectedCompetitionRegistrationLocalTeams(state: NewCompetitionStateModel) {
    const teams = state.competitions[state.selectedCompetition]?.registrationDetail?.localTeams;
    return teams ? [...teams].sort((a, b) => a.abbr.localeCompare(b.abbr)) : [];
  }

  @Selector()
  static selectedCompetitionRegistrationEmail(state: NewCompetitionStateModel) {
    return (
      state.competitions[state.selectedCompetition]?.registrationDetail?.contactEmail ||
      (state.competitions[state.selectedCompetition]?.club?.abbr || 'contact') + '@lbfa.be'
    );
  }

  @Selector()
  static selectedCompetitionRegistrationPrices(state: NewCompetitionStateModel) {
    return state.competitions[state.selectedCompetition]?.registrationPricing || [];
  }

  @Selector()
  static liveResultDiscipline(state: NewCompetitionStateModel) {
    return state.competitions[state.selectedCompetition]?.disciplines.find((d) => {
      const metadata = d.metadata as IDisciplineMetadata;
      return metadata?.id_am === state.selectedDiscipline;
    });
  }

  @Action(SelectOrLoadCompetition)
  selectOrLoadCompetition(ctx: StateContext<NewCompetitionStateModel>, action: SelectOrLoadCompetition) {
    if (ctx.getState().competitions[action.eventNumber] && !action.forceLoading) {
      return ctx.setState(
        produce((draft: NewCompetitionStateModel) => {
          draft.selectedCompetition = action.eventNumber;
        }),
      );
    } else {
      return this.apiService.getFullCompetitionByEventNumber(action.eventNumber).pipe(
        tap(({ competition, results, logs, previousSb }) => {
          return ctx.setState(
            produce((draft: NewCompetitionStateModel) => {
              draft.selectedCompetition = competition.eventNumber;
              draft.logs[competition.eventNumber] = logs;
              draft.lastSeasonBests[competition.eventNumber] = previousSb;
              this.setCompetitionAndResults(draft, competition, results);
            }),
          );
        }),
      );
    }
  }

  @Action(CreateNewCompetition)
  createNewCompetition(ctx: StateContext<NewCompetitionStateModel>, action: CreateNewCompetition) {
    return this.apiService.createCompetition(action.competition, action.organizer).pipe(
      tap((competition) => {
        return ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.competitions[competition.eventNumber] = competition;
          }),
        );
      }),
    );
  }

  @Action(SaveCompetitionData)
  saveCompetitionMetadata(ctx: StateContext<NewCompetitionStateModel>, action: SaveCompetitionData) {
    return this.apiService.saveCompetitionData(action.eventNumber, action.data).pipe(
      tap((data) => {
        return ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.competitions[action.eventNumber] = data;
          }),
        );
      }),
    );
  }

  @Action(CreateResult)
  createResult(ctx: StateContext<NewCompetitionStateModel>, action: CreateResult) {
    return this.apiService
      .createNewResult(action.eventNumber, action.result, action.athletes, action.recalculateRanks, action.children)
      .pipe(
        tap(({ results, updatedRanks }) => {
          return ctx.setState(
            produce((draft: NewCompetitionStateModel) => {
              for (const result of results) {
                let discipline = draft.competitions[action.eventNumber].disciplines.find(
                  (d) => d.id === result.disciplineId,
                );

                if (!discipline) {
                  const parentDiscipline = draft.competitions[action.eventNumber].disciplines.find(
                    (d) => d.id === results[0].disciplineId,
                  );
                  discipline = parentDiscipline?.children?.find((d) => d.id === result.disciplineId);
                }

                discipline?.results?.push({ id: result.id });
                draft.results[action.eventNumber][result.id] = result;
              }
              for (const res of updatedRanks) {
                draft.results[action.eventNumber][res.id].rank = res.rank;
              }
            }),
          );
        }),
        catchError((err) => {
          alert(err.error ? err.error : JSON.stringify(err));
          return throwError(() => err);
        }),
      );
  }

  @Action(EditResultData)
  editResultData(ctx: StateContext<NewCompetitionStateModel>, action: EditResultData) {
    return this.apiService
      .updateResultData(action.eventNumber, action.editedResult, action.recalculateRanks, action.disciplineParentId)
      .pipe(
        tap(({ treatedResult, totalRecalculated, recalculatedRanks }) => {
          return ctx.setState(
            produce((draft: NewCompetitionStateModel) => {
              draft.results[action.eventNumber][treatedResult.id] = treatedResult;

              if (totalRecalculated) {
                draft.results[action.eventNumber][totalRecalculated.id] = totalRecalculated;
              }

              if (action.recalculateRanks) {
                for (const res of recalculatedRanks) {
                  draft.results[action.eventNumber][res.id].rank = res.rank;
                }
              }

              this.store.dispatch(new ReloadCompetitionLogs(action.eventNumber));
            }),
          );
        }),
      );
  }

  @Action(UpdateResultsStatus)
  updateResultsStatus(ctx: StateContext<NewCompetitionStateModel>, action: UpdateResultsStatus) {
    return this.apiService.updateResultsValidation(action.status, action.resultIds).pipe(
      tap((isOk) => {
        if (isOk) {
          ctx.setState(
            produce((draft: NewCompetitionStateModel) => {
              action.resultIds.forEach((resultId) => {
                draft.results[ctx.getState().selectedCompetition][resultId].validationStatus = action.status;
              });
            }),
          );
        }
      }),
    );
  }

  @Action(DeleteEventResult)
  deleteEventResult(ctx: StateContext<NewCompetitionStateModel>, action: DeleteEventResult) {
    return this.apiService.deleteResult(action.eventNumber, action.resultId, action.recalculateRanks).pipe(
      tap((data) => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            if (action.recalculateRanks) {
              for (const res of data.updatedRanks) {
                draft.results[action.eventNumber][res.id].rank = res.rank;
              }
            }
            delete draft.results[action.eventNumber][action.resultId];
            for (const id of data.childrenIds) {
              delete draft.results[action.eventNumber][id];
            }
          }),
        );
        this.store.dispatch(new ReloadCompetitionLogs(action.eventNumber));
      }),
    );
  }

  @Action(EditDisciplineEventType)
  editDisciplineEventType(ctx: StateContext<NewCompetitionStateModel>, action: EditDisciplineEventType) {
    return this.apiService.updateDisciplineEventType(action.disciplineId, action.eventTypeId).pipe(
      tap((discipline) => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            if (action?.parentId) {
              const parentIndex = draft.competitions[draft.selectedCompetition].disciplines.findIndex(
                (d) => d.id === action.parentId,
              );

              const children = draft.competitions[draft.selectedCompetition].disciplines[parentIndex].children;

              if (children) {
                const childIndex = children.findIndex((d) => d.id === action.disciplineId);
                children[childIndex] = discipline;
              }
            } else {
              const index = draft.competitions[draft.selectedCompetition].disciplines.findIndex(
                (d) => d.id === discipline.id,
              );
              draft.competitions[draft.selectedCompetition].disciplines[index] = discipline;
            }
          }),
        );
      }),
    );
  }

  // TODO : add action to delete results without disciplines ?

  @Action(DeleteDisciplinesAndResults)
  deleteDisciplinesAndResults(ctx: StateContext<NewCompetitionStateModel>, action: DeleteDisciplinesAndResults) {
    return this.apiService
      .deleteAllDisciplinesAndResults(action.eventNumber, action.cleanFedInsideData, action.deleteFromError)
      .pipe(
        tap(() => {
          ctx.setState(
            produce((draft: NewCompetitionStateModel) => {
              draft.results[action.eventNumber] = {};
              draft.competitions[action.eventNumber].disciplines = [];
            }),
          );
          this.store.dispatch(new ReloadCompetitionLogs(action.eventNumber));
        }),
      );
  }

  @Action(DeleteFullCompetition)
  deleteFullCompetition(ctx: StateContext<NewCompetitionStateModel>, action: DeleteFullCompetition) {
    return this.apiService.deleteFullCompetition(action.eventNumber).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            delete draft.results[action.eventNumber];
            delete draft.competitions[action.eventNumber];
            delete draft.logs[action.eventNumber];
          }),
        );
      }),
    );
  }

  @Action(ReloadCompetitionLogs)
  reloadCompetitionLogs(ctx: StateContext<NewCompetitionStateModel>, action: ReloadCompetitionLogs) {
    return this.apiService.getCompetitionLogs(action.eventNumber).pipe(
      tap((logs) => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.logs[action.eventNumber] = logs;
          }),
        );
      }),
    );
  }

  @Action(AddOfficialForCompetition)
  addOfficialForCompetition(ctx: StateContext<NewCompetitionStateModel>, action: AddOfficialForCompetition) {
    return this.apiService.linkOfficialWithCompetition(action.eventNumber, action.official).pipe(
      tap((competition) => {
        this.store.dispatch(new ReloadCompetitionLogs(action.eventNumber));
        return ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.competitions[competition.eventNumber] = competition;
          }),
        );
      }),
    );
  }

  @Action(DeleteContactOfCompetition)
  deleteContactOfCompetition(ctx: StateContext<NewCompetitionStateModel>, action: DeleteContactOfCompetition) {
    return this.apiService.removeContactOfCompetition(action.eventNumber, action.contactId).pipe(
      tap((competition) => {
        this.store.dispatch(new ReloadCompetitionLogs(action.eventNumber));
        return ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.competitions[competition.eventNumber] = competition;
          }),
        );
      }),
    );
  }

  @Action(ProcessResultFileUpload)
  processResultFileUpload(ctx: StateContext<NewCompetitionStateModel>, action: ProcessResultFileUpload) {
    return this.apiService
      .uploadResultFile(action.eventNumber, action.file, action.source, action.registrationSchedule)
      .pipe(
        tap(({ competition, results }) => {
          ctx.setState(
            produce((draft: NewCompetitionStateModel) => {
              this.setCompetitionAndResults(draft, competition, results);
            }),
          );
          this.store.dispatch(new ReloadCompetitionLogs(action.eventNumber));
        }),
      );
  }

  @Action(SaveRegistrationDetails)
  saveRegistrationDetails(ctx: StateContext<NewCompetitionStateModel>, action: SaveRegistrationDetails) {
    return this.apiService.saveRegistrationDetails(action.registrationDetails).pipe(
      tap((registrationDetails) => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.competitions[action.registrationDetails.eventNumber].registrationDetail = registrationDetails;
          }),
        );
        this.store.dispatch(new ReloadCompetitionLogs(action.registrationDetails.eventNumber));
      }),
    );
  }

  @Action(AddAthleteOrClubAsVip)
  addAthleteOrClubAsVip(ctx: StateContext<NewCompetitionStateModel>, action: AddAthleteOrClubAsVip) {
    return this.apiService.addAthleteOrClubAsVip(action.data).pipe(
      tap((vip) => {
        const eventNumber = ctx.getState().selectedCompetition;
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[eventNumber];
            if (competition?.registrationDetail?.registrationsVip) {
              competition.registrationDetail.registrationsVip.push(vip);
            } else if (competition?.registrationDetail) {
              competition.registrationDetail.registrationsVip = [vip];
            } else {
              competition.registrationDetail = {
                registrationsVip: [vip],
              } as IRegistrationDetail;
            }
          }),
        );
      }),
    );
  }

  @Action(RemoveVip)
  removeVip(ctx: StateContext<NewCompetitionStateModel>, action: RemoveVip) {
    return this.apiService.removeVipFromRegistration(action.vipId).pipe(
      tap(() => {
        const eventNumber = ctx.getState().selectedCompetition;
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[eventNumber];
            if (competition?.registrationDetail?.registrationsVip) {
              competition.registrationDetail.registrationsVip = competition.registrationDetail.registrationsVip.filter(
                (vip) => vip.id !== action.vipId,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(SaveCustomRegistrationRule)
  saveCustomRegistrationRule(ctx: StateContext<NewCompetitionStateModel>, action: SaveCustomRegistrationRule) {
    return this.apiService.saveRegistrationCustomRule(action.data).pipe(
      tap((rule) => {
        const eventNumber = ctx.getState().selectedCompetition;
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[eventNumber];
            const ruleIds = (competition?.registrationDetail?.registrationsRules || []).map((r) => r.id);
            if (ruleIds.includes(rule.id) && competition?.registrationDetail?.registrationsRules) {
              competition.registrationDetail.registrationsRules = competition.registrationDetail.registrationsRules.map(
                (r) => (r.id === rule.id ? rule : r),
              );
            } else {
              if (competition?.registrationDetail?.registrationsRules) {
                competition.registrationDetail.registrationsRules.push(rule);
              } else if (competition?.registrationDetail) {
                competition.registrationDetail.registrationsRules = [rule];
              } else {
                competition.registrationDetail = {
                  registrationsRules: [rule],
                } as IRegistrationDetail;
              }
            }
          }),
        );
      }),
    );
  }

  @Action(DeleteRegistrationRule)
  deleteRegistrationRule(ctx: StateContext<NewCompetitionStateModel>, action: DeleteRegistrationRule) {
    return this.apiService.removeCustomRuleFromRegistration(action.ruleId).pipe(
      tap(() => {
        const eventNumber = ctx.getState().selectedCompetition;
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[eventNumber];
            if (competition?.registrationDetail?.registrationsRules) {
              competition.registrationDetail.registrationsRules =
                competition.registrationDetail.registrationsRules.filter((rule) => rule.id !== action.ruleId);
            }
          }),
        );
      }),
    );
  }

  @Action(SaveLocalTeamsList)
  saveLocalTeamsList(ctx: StateContext<NewCompetitionStateModel>, { competitionId, teams }: SaveLocalTeamsList) {
    return this.apiService.saveLocalTeamsList(competitionId, teams).pipe(
      tap((teams) => {
        const eventNumber = ctx.getState().selectedCompetition;
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[eventNumber];
            if (competition?.registrationDetail) {
              competition.registrationDetail.localTeams = teams;
            } else {
              competition.registrationDetail = {
                localTeams: teams,
              } as IRegistrationDetail;
            }
          }),
        );
      }),
    );
  }

  setCompetitionAndResults(draft: NewCompetitionStateModel, competition: ICompetition, results: IResult[]) {
    draft.competitions[competition.eventNumber] = competition;
    for (const result of results) {
      if (draft.results[competition.eventNumber]) {
        draft.results[competition.eventNumber][result.id] = result;
      } else {
        draft.results[competition.eventNumber] = { [result.id]: result };
      }
    }
  }

  @Action(SavePaymentOption)
  savePaymentOption(ctx: StateContext<NewCompetitionStateModel>, { competitionId, option }: SavePaymentOption) {
    return this.apiService.saveRegistrationPaymentOption(competitionId, option).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const registrationDetail = draft.competitions[draft.selectedCompetition]?.registrationDetail;
            if (registrationDetail) {
              registrationDetail.paymentOption = option;
            }
          }),
        );
      }),
    );
  }

  @Action(SavePricing)
  savePricing(ctx: StateContext<NewCompetitionStateModel>, { pricing }: SavePricing) {
    return this.apiService.saveRegistrationPricing(pricing).pipe(
      tap((p) => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[draft.selectedCompetition];
            if (competition.registrationPricing) {
              if ('id' in pricing) {
                const pIndex = competition.registrationPricing.findIndex((p) => p.id === pricing.id);
                competition.registrationPricing[pIndex] = p;
              } else {
                competition.registrationPricing.push(p);
              }
            }
          }),
        );
      }),
    );
  }

  @Action(DeleteRegistrationPricing)
  deleteRegistrationPricing(ctx: StateContext<NewCompetitionStateModel>, { pricingId }: DeleteRegistrationPricing) {
    return this.apiService.deleteRegistrationPricing(pricingId).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const competition = draft.competitions[draft.selectedCompetition];
            if (competition.registrationPricing) {
              competition.registrationPricing = competition.registrationPricing.filter((p) => p.id !== pricingId);
            }
          }),
        );
      }),
    );
  }

  @Action(ResetShop)
  resetShop(ctx: StateContext<NewCompetitionStateModel>, { eventNumber }: ResetShop) {
    return this.apiService.resetShop(eventNumber).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            const registrationDetail = draft.competitions[eventNumber]?.registrationDetail;
            if (registrationDetail) {
              registrationDetail.managed = false;
              registrationDetail.validated = false;
            }
          }),
        );
      }),
    );
  }

  @Action(ReSyncShop)
  reSyncShop(ctx: StateContext<NewCompetitionStateModel>, { eventNumber }: ResetShop) {
    return this.apiService.reSyncShop(eventNumber).pipe(
      tap(() => {
        //TODO : implement this
      }),
    );
  }

  @Action(GenerateSyncCode)
  generateSyncCode(ctx: StateContext<NewCompetitionStateModel>, { eventNumber }: ResetShop) {
    return this.apiService.generateLiveSyncCode(eventNumber).pipe(
      tap((data) => {
        ctx.setState(
          produce((draft: NewCompetitionStateModel) => {
            draft.competitions[eventNumber].syncCode = data.syncCode;
          }),
        );
      }),
    );
  }

  @Action(LoadDisciplineLiveResults)
  loadDisciplineLiveResults(ctx: StateContext<NewCompetitionStateModel>, action: LoadDisciplineLiveResults) {
    ctx.setState(
      produce((draft) => {
        draft.selectedDiscipline = action.disciplineIdAM;
      }),
    );
  }

  @Action(PushUpdatedData)
  pushUpdatedData(ctx: StateContext<NewCompetitionStateModel>, action: PushUpdatedData) {
    ctx.setState(
      produce((draft) => {
        for (const discipline of action.data.disciplines) {
          const competition = draft.competitions[action.eventNumber];
          if (competition) {
            const d = competition.disciplines.find((d) => d.id === discipline.id);
            if (d) {
              (d.metadata as unknown) = discipline.metadata;
            }
          }
        }
        for (const result of action.data.results) {
          if (draft.results[action.eventNumber]) {
            draft.results[action.eventNumber][result.id] = result;
          } else {
            draft.results[action.eventNumber] = { [result.id]: result };
          }
        }
      }),
    );
  }

  @Action(ReplaceCompetitionMetadata)
  replaceCompetitionMetadata(ctx: StateContext<NewCompetitionStateModel>, action: ReplaceCompetitionMetadata) {
    ctx.setState(
      produce((draft) => {
        const competition = draft.competitions[action.eventNumber];
        if (competition) {
          (competition.metadata as unknown) = action.data.metadata;
          for (const discipline of competition.disciplines) {
            const metadata = action.data.disciplines.find((d) => d.id === discipline.id)?.metadata;
            if (!metadata || (metadata?.rounds?.[0]?.participants?.length || 0) === 0) {
              (discipline.metadata as unknown) = metadata;
            }
          }
        }
      }),
    );
  }
}
